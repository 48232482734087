.albums

	margin-bottom: 0.6rem
	.album
		width: 100%
		background-size: cover
		height: 1.4rem
		// display: flex
		// justify-content: space-between
		// align-items: center
		position: relative
		.name-count
			position: absolute
			right: 0
			top: 0
			padding: 0.1rem 0.15rem
			color: #ffffff
			font-size: 0.24rem
			.name
				font-weight: bolder
				line-height: 2em
				text-shadow: 0.01rem 0.04rem 0.05rem black
			.count
				font-style: italic
				text-align: center
				text-shadow: 0.01rem 0.04rem 0.05rem black
				

	.movies
		position: absolute
		left: 0
		bottom: -0.3rem
		line-height: 0
		padding: 0.05rem
		display: flex
		flex-direction: row-reverse
		justify-content: flex-end
		align-items: flex-end
		
		.movie
			margin-right: 0.05rem
			.cover
				border-radius: 0.05rem
				overflow: hidden
			&:nth-child(1)
				.cover
					width: 1rem
					height: 1.4rem
			&:nth-child(2)
				.cover
					width: 0.7rem
					height: 0.9rem
					margin-bottom: 0.05rem
					// transform: skewY(-5deg)
					// margin-left: -0.05rem
			&:nth-child(3)
				.cover
					width: 0.5rem
					height: 0.7rem
					margin-bottom: 0.08rem
					
					
		
			

				