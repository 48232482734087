.auth-page
	text-align: center

.footer
	position: fixed
	bottom: 0
	display: flex
	justify-content: space-between
	align-items: center
	padding: .03rem .1rem
	background-color: #202020
	
	
	padding-bottom: constant(safe-area-inset-bottom)
	padding-bottom: env(safe-area-inset-bottom) !important
	left: 0
	right: 0
	z-index: 999
	
	.nav
		display: flex
		flex: 1
		justify-content: space-evenly
		align-items: center
		padding-bottom: 10px
		color: #999
		.item
			padding: 10px 2px 8px
			font-size: .18rem
			&.current
				color: #fff
				text-shadow: 0px 3px 10px #fff
.homepage
	
	.header
		display: flex
		justify-content: space-between
		align-items: center
		padding: .03rem .1rem
		background: #111
		z-index: 999
		.search
			width: .32rem
			height: .32rem
			margin: 0 .1rem
			padding: 0 .1rem 0.02rem
			border-bottom: 0.02rem solid transparent
			&.current
				border-bottom-color: #fff
				color: #fff
		.nav
			display: flex
			flex: 1
			justify-content: space-evenly
			align-items: center
			padding-bottom: 10px
			
			.item
				padding: 6px 2px 8px
				// margin: 0 10px
				font-size: 16px
				border-bottom: 2px solid transparent
				color: #999
				&.current
					border-bottom-color: #fff
					color: #fff
		.search
			font-size: .15rem

	
		.search
			font-size: .15rem
	.page-container
		height: calc(100vh - .52rem)
		overflow: auto
		box-sizing: border-box
		padding-bottom: calc(40px + env(safe-area-inset-bottom))
		.swiper-wrapper 
			z-index:3
	
		.swiper-pagination 
			z-index: 2
		
		.swiper-button-next, .swiper-button-prev
			z-index: 4
	
		.banners
			width: 100%
			height: 1.50rem
			.splide-item
				height: 1.50rem
				width: 100%
				overflow: hidden
		
		.cats
			display: flex
			justify-content: space-between
			align-items: flex-start
			padding: .2rem .3rem 0
			.cat
				text-align: center
				.icon
					width: .32rem
					height: .32rem
				.name
					line-height: 1
					font-size: .14rem
.movies-section
	.section-header
		display: flex
		padding: .15rem .1rem .05rem
		.name
			font-size: 0.16rem
			color: #333
			span
				padding: 0 .05rem
				color: #0d09e4
				font-family: Arial, Helvetica, sans-serif
		.sub-name
			font-size: 0.13rem
			color: #999
			padding-right: .1rem
	.section-content
		.movies
			display: flex
			padding: 5px
			width: auto
			.my-masonry-grid_column
				padding-right: 0
				background-clip: padding-box
				.movie
					box-sizing: border-box
					text-align: center
					padding: 5px
					position: relative
					
					overflow: hidden
					.cover-box
						position: relative
						width: 100%
						padding-bottom: calc(7/5*100%)
						// aspect-ratio: 45 / 67
						.update-status
							position: absolute
							bottom: 0
							left: 0
							right: 0
							background: rgba(0,0 ,0 ,.5 )
							color: #fff
							font-size: 0.14rem
							// font-style: italic
							display: flex
							justify-content: space-between
							align-items: center
							padding: 5px 0
							border-radius: 0 0 0.07rem 0.07rem
							&.all-updated
								justify-content: center
							.text
								padding: 0 .05rem
							.num
								padding: 0 .05rem
								color: #ffeb3b
						.cover
							width: 100%
							height: 100%
							position: absolute
							bottom: 0
							left: 0
							right: 0
							top: 0
							border-radius: 0.07rem
					.name
						text-align: center
						font-size: 16px
						line-height: 1.3
						padding-top: .05rem
						padding-bottom: .1rem
						color: #222
				a:link
					.name
						color: #030303
				a:visited
					.name
						color: #7c7c7c
		.movies-list
			padding: .05rem
			.movie
				box-sizing: border-box
				overflow: hidden
				text-align: center
				display: flex
				padding: .05rem
				.cover-box
					position: relative
					.update-status
						position: absolute
						bottom: 0
						left: 0
						right: 0
						background: rgba(0,0 ,0 ,.5 )
						color: #fff
						font-size: 0.14rem
						// font-style: italic
						display: flex
						justify-content: center
						align-items: center
						padding: 5px 0
						.text
							padding: 0 .05rem
						.num
							padding: 0 .05rem
							color: #ffeb3b
					.cover
						width: 1.41rem
						height: .80rem
						border-radius: 0.03rem
						background: #444
						background-size: cover
				
				.meta
					flex: 1
					width: 0
					flex-shrink: 0
					text-align: right
					padding-right: .1rem
					.progress
					.name
						font-size: .15rem
						line-height: 1.3
						padding-bottom: .05rem
						color: #222
					.episode
						color: #b31b1b
					.progeress
						color: #999
				.remove
					// background-color: #b31b1b
					display: flex
					justify-content: center
					align-items: center
					.remove-icon
						width: .32rem
						height: .32rem
				a:link
					.name
						color: #030303
				a:visited
					.name
						color: #7c7c7c
			
				
			
	.section-footer
		

.login-tip
	padding: .50rem .1rem
	text-align: center
	display: flex
	justify-content: center
	align-items: center
	flex-direction: column
	.tip
		padding: .3rem 0
	.login
		background-color: green
		color: #fff
		border-radius: 0.03rem
		padding: .05rem .1rem

.tags
	display: flex
	justify-content: flex-start
	align-items: center
	flex-wrap: wrap
	padding: .1rem
	.tag
		margin: .05rem
		background: #d8d8d8
		color: #333
		padding: .05rem .1rem
		border-radius: 0.03rem
		cursor: pointer
			
		&.current
			background-color: #69b011
			color: #fff
