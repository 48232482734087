
	
a
	text-decoration: none

.ug
	font-family: "UKIJ Ekran"
	direction: rtl
	line-height: .15rem
	font-size: .15rem
.en
	font-family: Arial, Helvetica, sans-serif
.ltr
	direction: ltr
.rtoast
	font-family: "UKIJ Ekran" !important
	direction: rtl
	line-height: .15rem

.loading-wrap
	width: 100%
	height: 100%
	display: flex
	justify-content: center
	align-items: center


.loading
	width: 100vw
	height: 100vh
	display: flex
	align-items: center
	justify-content: center
	.text
		padding: .20rem
		background-color: #ffffff
		color: #000000
		border-radius: 5px


.modal
	width: 100vw
	height: 100vh
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	.mask
		width: 100vw
		height: 100vh
		position: fixed
		top: 0
		left: 0
		right: 0
		bottom: 0
		background: #00000049
		z-index: -1
		display: flex
		justify-content: center
		align-items: center
	.modal-content
		padding: 30px
		border-radius: 10px
		box-sizing: border-box
		background: #ffffff
		z-index: 999
		width: auto
		height: auto
		.modal-form
		.modal-name
			text-align: center
			font-size: 20px
		.key-value
			padding: 10px 0
			.key
				color: #888888
				font-size: 14px
				padding-bottom: 10px
			.value
				width: 100%
				box-sizing: border-box
				border: 1px solid #dedede
				padding: 10px
		.submit
			text-align: center
			background: $btn
			color: #fff
			padding: 10px 30px
			border-radius: 3px

.contact
	position: relative
	display: block
	margin-left: 0
	margin-right: 0
	padding: 0
	box-sizing: border-box
	font-size: inherit
	text-align: right
	text-decoration: none
	line-height: inherit
	border-radius: 0
	overflow: hidden
	color: inherit
	background-color: transparent
	border: none
	width: 100%
	&::after
		display: none
		position: relative

.not-fount
	text-align: center
	padding: .1rem .2rem