.user-page
	// background: #f5f5f5
	padding: 0.15rem
	box-sizing: border-box
	width: 100%
	.login-box
		padding: 0.15rem 0.1rem
		margin: 0.1rem 0.25rem 0
		background: #76AE6F
		border-radius: 0.05rem 0.05rem 0 0
		display: flex
		flex-direction: column
		align-content: center
		justify-content: center
		.login-tip
			color: #fff
			font-size: 0.15rem
			line-height: 2em
			text-align: center
			padding: 0
		.login
			width: 70%
			border: 0.01rem solid transparent
			margin: 0.15rem auto 0.05rem
			background: #fff
			text-align: center
			padding: 0.05rem
			border-radius: 0.03rem
			font-size: 0.16rem
	.userinfo
		display: flex
		justify-content: flex-start
		align-items: center
		
		padding: 0.25rem 0.1rem
		margin: 0.1rem 0.25rem 0.01rem
		background: #76AE6F
		border-radius: 0.05rem 0.05rem 0 0
		box-shadow: -0.01rem 0.1rem 0.06rem 0.02rem #33333373
		.btn
			background-color: $blue
			color: #ffffff
			text-align: center
			padding: 0.1rem 0.05rem
			border-radius: 0.03rem
			font-size: 0.14rem
		.avatar
			width: 0.48rem
			height: 0.48rem
			border-radius: 0.03rem
		.meta
			flex: 1
			padding-inline-start: 0.15rem
			.nickname
				color: #ffffff
			.id
				display: flex
				justify-content: flex-start
				align-items: center
				.text
					color: #ffffff
					padding-left: 0.05rem
				.copy-icon
					width: 0.24rem
					height: 0.24rem
		.expire-at
			text-align: center
			.text
				font-size: 0.14rem
				color: #fff
			.date
				direction: ltr
				color: #fff
				font-size: 0.14rem
				margin-top: 10px
			

	.list
		border-radius: 0.05rem
		overflow: hidden
		margin-bottom: 0.2rem
		border: 0.01rem solid #e5e5e5
		.list-item
			padding: 0.15rem
			display: flex
			align-items: center
			background: #ffffff
			border-bottom: 0.01rem solid #e5e5e5
			&:last-child
				border-bottom: none
			&.vip
				background-color: #FEFBF4
				color: #966210
				display: flex
				justify-content: space-between
				align-items: center
				.name
					display: flex
					justify-content: flex-start
					align-items: center
					.title
						font-size: 0.15rem
						padding: 0 0.1rem
				.price
					font-size: 0.14rem
			.icon
				width: 0.24rem
				height: 0.24rem
			.text
				padding: 0 0.1rem
				color: #888
				font-size: 0.15rem
				flex: 1
				&.tip
					color: #f44336
					font-size: 0.13rem
					text-align: center
			.more-icon
				width: 0.24rem
				height: 0.24rem
				transform: rotate(180deg)

	.section-name
		display: flex
		justify-content: center
		align-items: center
		padding: 0.15rem
		.right-line
			height: 0.01rem
			width: 0.75rem
			background-color: #dadada
			display: flex
			align-items: center
			justify-content: flex-start
			flex-direction: row-reverse
			&:after
				content: ""
				width: 0.13rem
				border-radius: 50%
				height: 0.13rem
				background: #dadada
		.name
			padding: 0 0.15rem
			text-align: center
			color: #333
			font-size: 0.15rem
		.left-line
			@extend .right-line
			flex-direction: row
	.vip-description
		color: #333
		line-height: 2em
		font-size: 0.13rem
		.desc-title
			display: flex
			align-items: center
			background-image: linear-gradient(to right,#f5f5f5, #76AE6F)
			border-radius: 0.03rem
			font-size: 0.15rem
			padding: 0.03rem 0.05rem
			&::before
				content: ''
				margin-left: 0.05rem
				width: 0.03rem
				height: 0.15rem
				background: #fff
