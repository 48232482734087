@import-normalize
@import font
@import variable
@import common


// @import templates

// @import app-page
// @import home-page
// @import auth-page

// @import list-page
// @import category-page
// @import userlist-page
// @import detail-page

// @import config-page

// @import user-page

@import home_page
@import user_page
@import search_page
@import albums_page
@import album_page
@import category_page
@import player_page