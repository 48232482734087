.search-page
	// background: #f5f5f5
	box-sizing: border-box
.search-box
	padding: 0.1rem 0.15rem
	.search-form
		display: flex
		justify-content: space-between
		align-items: stretch
		
		font-size: 0.18rem
		
		background: #fff
		overflow: hidden
		.input
			flex: 1
			border: none
			padding: 10px
			border: 1px solid #f1f1f1
			border-radius: 0 5px 5px 0
			&:focus
				outline: none

		.submit
			border: 1px solid #f1f1f1
			background: #f1f1f1
			color: #333
			padding: 10px 30px
			border-radius: 5px 0 0 5px
			

.section
	padding: 0.05rem 0.15rem
	.section-name
		line-height: 2em
	.section-content
		.keywords
			display: flex
			flex-wrap: wrap
			.keyword
				margin: 0.05rem
				background: #d6da01
				color: #333
				padding: 0.05rem
				font-size: 0.14rem
				border-radius: 0.03rem