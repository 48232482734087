.player-page
	width: 100%
	padding-bottom: calc(40px + env(safe-area-inset-bottom))
	.player-box
		width: 100%
		position: sticky
		// top: 0.4rem
		.poster
			overflow: hidden
			.image
				width: 100%
				height: 100%
				position: absolute
				top: 0
				display: block
				
		.player-wrapper
			// background-color: #1f1f1f
			position: relative
			padding-top: 56.25%
			background-size: cover
			background-repeat: no-repeat
			background-position: center
			.react-player
				position: absolute
				top: 0
				left: 0
			.payment-box
				position: absolute
				top: 0
				bottom: 0
				left: 0
				right: 0
				height: 100%
				width: 100%
				background: rgba(0,0 , 0,.6 )
				

				color: #fff
				z-index: 999
				display: flex
				justify-content: center
				flex-direction: column
				align-items: center
				box-sizing: border-box
				.title
					font-size: 0.18rem
					padding: 0.15rem
				.products
					padding: 0 0.1rem
					width: 80%
					border-radius: 0.05rem
					box-sizing: border-box
					border: 0.01rem solid #c8c8c8
					.product
						display: flex
						justify-content: space-between
						align-items: center
						padding: 0.06rem 0
						border-bottom: 0.01rem solid #ffffff31
						&:last-child
							border: none
						.name
						.price
						.pay
							background: #6db040
							padding: 5px 10px
							border-radius: 3px
							font-size: 15px
							line-height: 1.5em
						

	.resolutions
		display: flex
		justify-content: space-around
		align-items: center
		background: #d6d6d6
		.resolution
			padding: 0.03rem 0.05rem
			border-radius: 0.03rem
			flex: 1
			margin: 0.05rem 0.1rem
			display: flex
			justify-content: center
			align-items: center
			.icon
				width: 0.16rem
				height: 0.16rem
			.text
				padding-right: 0.1rem
				font-size: 0.14rem
				color: #666
				text-align: center
				&.current
					color: $navy

	.header
		padding: 0.15rem
		display: flex
		justify-content: space-between
		align-items: center
		background: #ffffff
		border-bottom: 0.01rem solid #dedede
		.right
			flex: 1
			width: 0
			overflow: hidden
			text-overflow: ellipsis
			padding-left: 0.05rem
			.name
				font-size: 0.18rem
				line-height: 2em
				color: $blue
			.episode
				padding-top: 0.03rem
				font-size: 0.14rem
				color: $orange
		.left
			display: flex
			justify-content: space-between
			align-items: center
			flex-shrink: 0
			.icon-home
				width: 0.32rem
				height: 0.32rem
			.icon
				width: 0.24rem
				height: 0.24rem
				&.moment
					margin-right: 0.2rem

	.desc
		background: #f5f5f5
		font-size: 0.23rem
		color: #78909C
		.rich-text
			padding: 0.05rem 0.15rem
			text-align: justify
			text-indent: 2em
			line-height: 2em
			&:first-child
				padding-top: 0.05rem
		.rich-img
			width: calc(100% + 0.3rem)
			margin: 0 -0.15rem
			display: block

	.episode-section
		display: flex
		justify-content: space-between
		align-items: center
		padding: 0.1rem 0.15rem
		// position: sticky
		// top: 2.5rem
		background: #ffffff
		.name
			flex: 1
		.playing-episode
			color: #FF851B
		.toggle
			width: 0.32rem
			height: 0.32rem
			margin-right: 0.1rem
	.episodes
		background: #ffffff
		padding: 0.15rem
		display: flex
		justify-content: space-between
		align-items: flex-start
		.list-box
			display: flex
			border-radius: 0.03rem
			justify-content: flex-start
			align-items: flex-start
			flex-wrap: nowrap
			border: 0.01rem solid #d5d5d5
			overflow-x: auto
			&.show-all
				flex-wrap: wrap
				border-width: 0
				width: 100%
				.episode
					border: 0.01rem solid #d5d5d5
					margin: 0.03rem
					min-width: 16.8%
					border-radius: 0.03rem
					position: relative
					.vip
						position: absolute
						top: 0
						left: 0
						color: #FF851B
						background: #fff
						padding: 0.05rem
						font-style: italic
						border-radius: 0 0 0.12rem 0
						line-height: 1
					&:first-child
						border-right: 0.01rem solid #d5d5d5
			.episode
				text-align: center
				padding: 0.1rem
				border-right: 0.01rem solid #d5d5d5
				min-width: 0.6rem
				font-size: 0.14rem
				position: relative
				.vip
					position: absolute
					top: 0
					left: 0
					color: #FF851B
					background: #fff
					padding: 0.05rem
					font-style: italic
					border-radius: 0 0 0.12rem 0
					line-height: 1

				&:first-child
					border-right: 0
				&.current
					background: $orange
					color: #fff



	
